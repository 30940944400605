import { Divider } from '@mui/material';
import React, { Component } from 'react';
import { Footer, Tabs } from '../universalComponents';

export default class Mission extends Component {
  componentDidMount() {
    const { updateCurrentTab, mobile } = this.props;
    if (!mobile) updateCurrentTab(2);
  }

  render() {
    const { currentTab, mobile } = this.props;

    return (
      <div style={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
        {/* Navigation */}
        <div style={{ flex: '0 1 auto' }}>
          <Tabs currentTab={currentTab} mobile={mobile} />
        </div>
        {/* Main Content */}
        <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '7vh' }}>
          <div style={{ width: mobile ? '90vw' : '50vw', textAlign: 'center', fontSize: mobile ? '14pt' : '16pt', paddingLeft: '16vw', paddingRight: '16vw' }}>
            <div style={{ fontSize: mobile ? '25pt' : '35pt', fontWeight: 'bold' }}>AUTHENTIC. FAIR. RELIABLE.</div>
            <Divider sx={{ bgcolor: '#F32028', marginY: '20px', borderBottomWidth: 2 }} />
            <p style={{ marginTop: 0, marginBottom: '5px' }}>
              Kaden Trading Co is dedicated to saving money on your company's printing costs.
            </p>
            <p style={{ marginTop: '35px', marginBottom: '5px' }}>
              We are partnered with various name-brand printing companies, and can reliably supply OEM printers and toners no matter how large your organization.
            </p>
            <p style={{ marginTop: '35px', marginBottom: '5px' }}>
              Printing can be an expensive operating expense.
            </p>
            <p style={{ marginTop: '35px' }}>
              By working with us, we will guide you on how to get the most bang for your buck, so that you can print at the cheapest cost possible.
            </p>
          </div>
        </div>
        {/* Footer */}
        <div style={{ flex: '0 1 auto' }}><Footer /></div>
        {/* Graphics */}
        {/* {
          mobile ?
            <div style={{
              width: '10vw',
              position: 'absolute',
              top: 0,
              left: '5vw',
              bottom: 0,
              background: 'linear-gradient(to right, #0D95F2 0%, #0D95F2 33%, #F80871 33%, #F80871 66%, #FFE001 66%, #FFE001 100%)',
              zIndex: -2
            }} />
            :
            <>
              <img
                alt='fallingMan'
                style={{ height: '55vh', width: '16vw', position: 'absolute', bottom: '26vh', right: '28vw' }}
                src={require('../../assets/fallingMan.png')}
              />
              <img
                alt='parachuteMan'
                style={{ height: '40vh', width: '20vw', position: 'absolute', bottom: '32vh', right: '5vw' }}
                src={require('../../assets/parachuteMan.png')}
              />
            </>
        } */}
      </div>
    )
  }
}
