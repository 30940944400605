import { Home, Menu as MenuIcon, Paragliding, Print, SupportAgent, AccountCircle, ShoppingCart } from '@mui/icons-material';
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tab, Tabs, Avatar, Drawer } from '@mui/material';
import CheckoutDrawer from './CheckoutDrawer';
import React, { Component } from 'react';
import formatStringFunctions from '../../utils/formatString';

export default class TabsComponent extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      menu: false,
      anchor: null,
      displayName: null,
      cartHover: false,
      cartItems: [],
      cartDeals: [],
      cartSummary: null,
      tabs: [
        { name: 'home', slug: '', icon: <Home /> },
        { name: 'toners and supplies', slug: 'products', icon: <Print /> },
        // { name: 'services', slug: 'services', icon: <Handyman /> },
        { name: 'our mission', slug: 'mission', icon: <Paragliding /> },
        { name: 'contact us', slug: 'contact', icon: <SupportAgent /> },
        { name: 'login', slug: 'login', icon: <AccountCircle /> }
      ],
      token: null
    };
  }
  componentDidUpdate() {
    const { cartItems, cartDeals } = this.props;
    if (Array.isArray(cartItems) && JSON.stringify(cartItems) !== JSON.stringify(this.state.cartItems)) {
      this.setState({ cartItems });
    }
    if (Array.isArray(cartDeals) && JSON.stringify(cartDeals) !== JSON.stringify(this.state.cartDeals)) {
      this.setState({ cartDeals });
    }
  }
  componentDidMount() {
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    let stateObj = {};

    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      if (c.includes('user=')) {
        const obj = c.replace('user=', '').trim();
        const userObj = JSON.parse(obj);
        stateObj.displayName = `${userObj?.firstName ? userObj.firstName[0] : ''}. ${userObj?.lastName}`;
        stateObj.token = userObj.token;
      } else if (c.includes('cartItems=')) {
        const array = c.replace('cartItems=', '').trim();
        const cookieCartItems = JSON.parse(array);
        if (cookieCartItems.length > 0) stateObj.cartItems = cookieCartItems;
      } else if (c.includes('cartDeals=')) {
        const array = c.replace('cartDeals=', '').trim();
        const cookieCartDeals = JSON.parse(array);
        if (cookieCartDeals.length > 0) stateObj.cartDeals = cookieCartDeals;
      }
    }
    this.setState(stateObj);
  }

  render() {
    const { currentTab, mobile, updateCartItemsAndDeals } = this.props;
    const { capitalize } = formatStringFunctions();

    const openMenu = (event) => {
      this.setState({ menu: true, anchor: event.currentTarget });
    }
    const closeMenu = () => {
      this.setState({ menu: false, anchor: null });
    }
    const handleCheckout = () => {
      const productIDs = this.state.cartItems.map(cartItem => cartItem.stripeProductID);
      const products = this.state.cartItems.filter((value, index) => {
          const _value = JSON.stringify(value);
          return index === this.state.cartItems.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          });
        }).map(cartItem => {
        const count = productIDs.filter((pid) => (pid === cartItem.stripeProductID)).length;
        cartItem.quantity = count;
        return cartItem;
      });

      this.setState({ cartSummary: products });
    }
    const cartMouseEnter = () => {
      this.setState({ cartHover: true });
    }
    const cartMouseLeave = () => {
      this.setState({ cartHover: false });
    }
    const updateState = (newState) => {
      updateCartItemsAndDeals(newState);
    }

    return (
      <div>
        <Box sx={{ borderBottom: 1, borderColor: 'transparent', margin: '0 10px 0 10px', position: 'relative' }}>
          <div
            onMouseEnter={cartMouseEnter}
            onMouseLeave={cartMouseLeave}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: mobile ? 15 : 20,
              right: mobile ? 40 : 15,
              background: !mobile ? this.state.cartHover ? '#D3D3D3' : 'rgba(255,255,255,0.75)' : null,
              borderRadius: '20px',
              padding: '5px',
              zIndex: 2,
              display: 'flex'
            }}
          >
            {
              this.state.cartItems.length > 0 ?
              <Avatar sx={{
                bgcolor: '#0D95F2',
                width: mobile ? '15px' : '20px',
                height: mobile ? '15px' : '20px',
                fontSize: mobile ? '10pt' : null,
                position: 'absolute',
                right: -10,
                top: -10,
                padding: '3px'
              }}>
                {this.state.cartItems.length}
              </Avatar>
              : null
            }
            <ShoppingCart style={{ color: 'black' }} sx={!mobile ? { width: '34px', height: '34px' }: null} onClick={handleCheckout} />
          </div>
          {
            mobile ?
            <div style={{ display: 'flex' }}>
              <div onClick={() => { window.location.replace('/') }} style={{ color: 'black', display: 'flex', fontFamily: 'Bookman Old Style' }}>
                <img alt='logo' style={{ width: '140px', marginTop: '10px', background: 'rgba(255,255,255,0.9)' }} src={require('../../assets/full_logo.png')} />
              </div>
              <IconButton sx={{ marginLeft: 'auto', marginTop: '10px', color: '#000000' }} variant='contained' onClick={openMenu}>
                <MenuIcon />
              </IconButton>
              <Menu anchorEl={this.state.anchor} open={this.state.menu} onClose={closeMenu}>
                {
                  this.state.tabs.map(tab =>
                    <MenuItem
                      key={tab.slug}
                      onClick={() => { window.location.replace(`/${tab.name === 'login' && this.state.displayName ? 'account' : tab.slug}`) }}
                    >
                      <ListItemIcon>{tab.icon}</ListItemIcon>
                      <ListItemText primary={tab.name === 'login' && this.state.displayName ? 'Account' : capitalize(tab.name)} />
                    </MenuItem>
                  )
                }
              </Menu>
            </div>
            :
            <Tabs
              value={currentTab}
              indicatorColor='transparent'
              sx={{
                paddingTop: '10px',
                paddingX: '25px',
                color: 'black !important',
                position: 'relative',
                height: '75px',
                '& a': { borderRadius: 2 },
                '& a:hover': { backgroundColor: 'rgba(0,0,0,0.15)' }
              }}
            >
              {
                this.state.tabs.map((tab, index) => {
                  let content = (
                    <Tab href={`/${tab.slug}`} key={tab.slug}
                      style={{ borderBottom: currentTab === index ? '2px solid black' : null, fontSize: '12pt' }}
                      sx={{ marginLeft: index === 1 ? 'auto' : '2px', marginRight: index === this.state.tabs.length - 2 ? 'auto' : '2px', marginTop: '10px' }}
                      label={
                        <div style={{ color: 'black', fontFamily: 'Georgia,Times,Times New Roman,serif', fontWeight: 'bold' }}>
                          {capitalize(tab.name)}
                        </div>
                      }
                    />
                  )
                  if (tab.name === 'home') {
                    content = (
                      <Tab href='/' style={{ position: 'absolute' }} key='landing'
                        label={
                          <img alt='logo' style={{ width: '160px' }} src={require('../../assets/full_logo.png')} />
                        }
                      />
                    )
                  } else if (tab.name === 'login') {
                    content = (
                      <Tab
                        href={this.state.displayName ? 'account' : `/${tab.slug}`}
                        key={tab.slug}
                        style={{ borderBottom: currentTab === index ? '2px solid black' : null, fontSize: '12pt', position: 'absolute', right: '3vw' }}
                        sx={{
                          marginLeft: index === 1 ? 'auto' : '2px',
                          marginRight: index === this.state.tabs.length - 1 ? 'auto' : '2px',
                          marginTop: '10px',
                          backgroundColor: 'rgba(255,255,255,0.75)'
                        }}
                        label={
                          <div style={{
                            color: 'black',
                            fontFamily: 'Georgia,Times,Times New Roman,serif',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center'
                          }}>
                            {tab.icon}
                            <div style={{ marginLeft: '5px' }}>
                              { this.state.displayName ? this.state.displayName : capitalize(tab.name) }
                            </div>
                          </div>
                        }
                      />
                    )
                  }
                  return content;
                })
              }
            </Tabs>
          }
        </Box>
        <Drawer anchor='right' open={this.state.cartSummary !== null} onClose={() => {this.setState({ cartSummary: null })}}>
          <CheckoutDrawer
            token={this.state.token}
            updateState={updateState}
            mobile={mobile}
            cartDeals={this.state.cartDeals}
            cartSummary={this.state.cartSummary}
            closeDrawer={() => {this.setState({ cartSummary: null })}}
          />
        </Drawer>
      </div>
    )
  }
}
