import React, { Component } from 'react';
import axios from 'axios';
import { Close, Add, Remove, ShoppingCart } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';

const KADENTRADING_API_URL = 'https://api.kadentrading.com/api';

export default class CheckoutDrawer extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      cartSummary: [],
      cartDeals: []
    };
  }

  componentDidMount() {
    const { cartSummary, cartDeals } = this.props;
    this.setState({ cartSummary, cartDeals });
  }
  render() {
    const { closeDrawer, mobile, token, updateState } = this.props;

    const stripeCheckout = async () => {
        const { data } = await axios.post(`${KADENTRADING_API_URL}/checkout`,
          { products: this.state.cartSummary, deals: this.state.cartDeals },
          { headers: { 'Authorization': `Basic ${token}` } }
        );
        if (data.url) {
          window.location.replace(data.url);
          closeDrawer();
        }
    }
    const addItem = (item) => {
      const newSummary = this.state.cartSummary.map(cartItem => {
        if (cartItem.stripeProductID === item.stripeProductID) cartItem.quantity += 1;
        return cartItem;
      });
      this.setState({ cartSummary: newSummary });
      
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      let stateObj = {};

      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        if (c.includes('cartItems=')) {
          const array = c.replace('cartItems=', '').trim();
          const cookieCartItems = JSON.parse(array);
          if (cookieCartItems.length > 0) stateObj.cartItems = cookieCartItems;
          else stateObj.cartItems = [];
        }
      }
      const products = stateObj.cartItems.filter(cartItem => cartItem.stripeProductID === item.stripeProductID);
      if (products.length > 0) stateObj.cartItems.push(products[0]);

      const encodedItems = encodeURI(JSON.stringify(stateObj.cartItems));
      document.cookie = `cartItems=${encodedItems}`;

      updateState({ cartItems: stateObj.cartItems, cartDeals: this.state.cartDeals });
    }
    const removeItem = (item) => {
      const newSummary = this.state.cartSummary.map(cartItem => {
        if (cartItem.stripeProductID === item.stripeProductID) cartItem.quantity -= 1;
        return cartItem;
      });

      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      let stateObj = { cartSummary: newSummary.filter(cartItem => cartItem.quantity) };

      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        if (c.includes('cartItems=')) {
          const array = c.replace('cartItems=', '').trim();
          const cookieCartItems = JSON.parse(array);
          if (cookieCartItems.length > 0) stateObj.cartItems = cookieCartItems;
        } else if (c.includes('cartDeals=')) {
          const array = c.replace('cartDeals=', '').trim();
          const cookieCartDeals = JSON.parse(array);
          if (cookieCartDeals.length > 0) stateObj.cartDeals = cookieCartDeals;
        }
      }
      const productIndex = stateObj.cartItems.map(cartItem => cartItem.stripeProductID).indexOf(item.stripeProductID);
      const product = stateObj.cartItems[productIndex];

      if (stateObj.cartDeals) {
        const dealIndex = stateObj.cartDeals.map(deal => deal.productID).indexOf(product.sku);
        if (dealIndex >= 0) stateObj.cartDeals.splice(dealIndex, 1);
        
        const encodedDeals = encodeURI(JSON.stringify(stateObj.cartDeals));
        document.cookie = `cartDeals=${encodedDeals}`;
      }

      stateObj.cartItems.splice(productIndex, 1);

      const encodedItems = encodeURI(JSON.stringify(stateObj.cartItems));
      document.cookie = `cartItems=${encodedItems}`;

      updateState(stateObj);
    
      delete stateObj.cartItems;
      this.setState(stateObj);
    }
    return (
      <div style={{ width: mobile ? '100vw' : '25vw', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid black', backgroundColor: 'rgba(0,0,0,0.2)' }}>
            <ShoppingCart style={{ padding: '10px 0 10px 10px' }} />
            <h3 style={{ padding: '10px', margin: 0 }}>Cart</h3>
            <div style={{ marginLeft: 'auto' }} onClick={closeDrawer}>
                <Close style={{ cursor: 'pointer', padding: '10px' }} />
            </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {
                this.state.cartSummary.length > 0 ?
                this.state.cartSummary.map(cartItem => {
                    return (
                        <div key={cartItem._id} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                            <div style={{ width: '60%', display: 'flex', alignItems: 'center' }}>
                                {cartItem.productName}
                            </div>
                            <div style={{ width: '20%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <IconButton
                                  onClick={() => removeItem(cartItem)}
                                  sx={{ '&:hover': { backgroundColor: 'rgba(0,0,0,0.2)' }, color: '#000000' }}
                                  variant='contained'
                                ><Remove style={{ cursor: 'pointer', color: 'red' }} /></IconButton>
                                <IconButton
                                  onClick={() => addItem(cartItem)}
                                  sx={{ '&:hover': { backgroundColor: 'rgba(0,0,0,0.2)' }, color: '#000000' }}
                                  variant='contained'
                                ><Add style={{ cursor: 'pointer', color: '#0D95F2' }} /></IconButton>
                            </div>
                            <div style={{ width: '20%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                Qty: {cartItem.quantity}
                            </div>
                        </div>
                    )
                })
                :
                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                    <div>No Cart Items</div>
                </div>
            }
        </div>
        <div style={{ marginTop: '5vh', marginRight: '10px', marginLeft: 'auto' }}>
          <Button
            variant='contained'
            sx={{ bgcolor: '#0D95F2', '&:hover': { 'bgcolor': '#2273F2' }, color: 'white' }}
            onClick={stripeCheckout}
            disabled={this.state.cartSummary.length === 0}
          >Checkout</Button>
        </div>
      </div>
    )
  }
}
