import React, { Component } from 'react';
import { Button, Stack, TextField, Alert, Collapse, IconButton } from '@mui/material';
import { Footer } from '../universalComponents';
import quickLinks from '../../utils/quickLinks';
import { ChevronLeft, Close } from '@mui/icons-material';
import axios from 'axios';
const { openLocalSlug } = quickLinks();

const KADENTRADING_API_URL = 'https://api.kadentrading.com/api';

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      company: '',
      address: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: ''
      },
      token: null
    };
  }
  componentDidMount() {
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      if (c.includes('user=')) {
        const obj = c.replace('user=', '').trim();
        const userObj = JSON.parse(obj);
        
        this.setState({
          firstName: userObj.firstName, 
          lastName: userObj.lastName,
          email: userObj.email,
          phone: userObj.phone ? userObj.phone : '',
          company: userObj.company ? userObj.company : '',
          address: userObj.address.street1 ? userObj.address : {
            street1: '',
            street2: '',
            city: '',
            state: '',
            zip: ''
          },
          token: userObj.token,
          successAlert: false
        })
      }
    }
  }

  render() {
    const { mobile } = this.props;

    const linkHome = () => {
      openLocalSlug('/');
    }
    const updateAddress = (val, key) => {
      const address = this.state.address;
      address[key] = val;
      this.setState({ address });
    }
    const updateUserProfile = async () => {
      try {
        const { data } = await axios.put(
          `${KADENTRADING_API_URL}/updateUser`,
          {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phone: this.state.phone,
            company: this.state.company,
            address: this.state.address,
          },
          { headers: { 'Authorization': `Basic ${this.state.token}` } }
        );
          
        this.setState({ successAlert: true });
        if (data) document.cookie = `user=${JSON.stringify(data)}`;
      } catch (err) {
        console.log(err);
      }
    }
    return (
      <div style={{ flexDirection: 'column', display: 'flex', position: 'relative', height: mobile ? null : '100%' }}>
        <div style={{ flex: '0 1 auto' }}>
          <div onClick={linkHome} style={{ cursor: 'pointer' }}>
            <img alt='logo' style={{ width: '200px' }} src={require('../../assets/full_logo.png')} />
          </div>
        </div>
        <div style={{ flex: '1 1 auto', marginTop: mobile ? '3vh' : '8vh', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
          <div onClick={() => {openLocalSlug('/account')}} style={{
            position: 'absolute',
            top: '3vh',
            left: mobile ? '15vw' : '30vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '74px',
            borderRadius: '37px',
            border: '1px solid black',
            paddingRight: '5px',
            cursor: 'pointer',
            ':hover': { backgroundColor: 'rgba(0,0,0,0.2)' }
          }}><ChevronLeft />Back</div>
          <div style={{ fontSize: '35pt', fontWeight: 'bold', textAlign: 'center', marginBottom: '3vh', marginTop: mobile ? '10vh' : null }}>My Profile</div>
          <div style={{
            display: 'flex',
            flexDirection: 'column', 
            justifyContent: 'center',
            border: '2px solid black',
            padding: '4vh',
            width: mobile ? '60vw' : null,
            backgroundColor: 'white',
            borderRadius: '10px',
            marginBottom: '15px'
          }}>
            <Stack spacing={2}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField value={this.state.firstName} onChange={(e) => {this.setState({ firstName: e.target.value })}} fullWidth label='First name' />
                <TextField value={this.state.lastName} onChange={(e) => {this.setState({ lastName: e.target.value })}} fullWidth label='Last name' />
              </Stack>
              <Stack spacing={2}>
                <TextField
                  value={this.state.email}
                  onChange={(e) => {this.setState({ email: e.target.value })}}
                  fullWidth
                  autoComplete='username'
                  type='email'
                  label='Email address'
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  type='tel'
                  value={this.state.phone}
                  onChange={(e) => {this.setState({ phone: e.target.value })}}
                  fullWidth
                  label='Phone'
                />
                <TextField value={this.state.company} onChange={(e) => {this.setState({ company: e.target.value })}} fullWidth label='Company' />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField value={this.state.address.street1} onChange={(e) => {updateAddress(e.target.value, 'street1')}} fullWidth label='Street1' />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField value={this.state.address.street2} onChange={(e) => {updateAddress(e.target.value, 'street2')}} fullWidth label='Street2' />
                <TextField value={this.state.address.city} onChange={(e) => {updateAddress(e.target.value, 'city')}} fullWidth label='City' />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField value={this.state.address.state} onChange={(e) => {updateAddress(e.target.value, 'state')}} fullWidth label='State' />
                <TextField value={this.state.address.zip} onChange={(e) => {updateAddress(e.target.value, 'zip')}} fullWidth label='Zip' />
              </Stack>
              <Stack spacing={2}>
                <Button fullWidth sx={{ bgcolor: '#0D95F2' }} variant='contained' onClick={updateUserProfile}>Update</Button>
                <Collapse in={this.state.successAlert}>
                  <Alert action={
                      <IconButton
                        color='inherit'
                        size='small'
                        onClick={() => {this.setState({ successAlert: false })}}
                      >
                        <Close fontSize='inherit' />
                      </IconButton>
                    } sx={{ mb: 2 }}>
                    Successfully updated profile
                  </Alert>
                </Collapse>
              </Stack>
            </Stack>
          </div>
        </div>
        <div style={{ flex: '0 1 auto' }}>
          <Footer />
        </div>
        {/* <div style={{
          width: mobile ? '10vw' : '4vw',
          position: 'absolute',
          left: mobile ? '5vw' : '2vw',
          top: 0,
          bottom: 0,
          background: 'linear-gradient(to right, #0D95F2 0%, #0D95F2 33%, #F80871 33%, #F80871 66%, #FFE001 66%, #FFE001 100%)',
          zIndex: -2
        }} /> */}
      </div>
    )
  }
}
