import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { Component } from 'react';
import FilterList from '../components/filterList';

export default class FilterDialog extends Component {
    render() {
        const { updateFilters, filters, close } = this.props;

        return (
            <div>
                <DialogTitle>Filters</DialogTitle>
                <DialogContent>
                  <FilterList filters={filters} updateFilters={updateFilters} />
                </DialogContent>
                <DialogActions>
                  <Button variant='contained' sx={{ bgcolor: '#0D95F2', '&:hover': { 'bgcolor': '#2273F2' }, color: 'white' }} onClick={close}>Close</Button>
                </DialogActions>
            </div>
        )
    }
}
