import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Contact from './components/ContactPage';
import LandingPage from './components/LandingPage';
import Mission from './components/MissionPage';
import Products from './components/ProductsPage';
import Services from './components/ServicesPage';
import Login from './components/LoginPage';
import SignUp from './components/SignUpPage';
import Account from './components/AccountPage';
import Profile from './components/ProfilePage';
import Deals from './components/DealsPage';
import OrderHistory from './components/OrderHistoryPage';
import CheckoutSplash from './components/CheckoutSplashPage';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      mobile: false,
      currentTab: 0
    };
  }

  componentDidMount() {
    this.setState({ mobile: window.innerWidth <= 640 });
  }

  render() {
    const updateCurrentTab = (tabIndex) => {
      this.setState({ currentTab: tabIndex });
    }

    return (
      <div className='App'>
        {/* Graphics */}
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          {
            this.state.currentTab === 4 || this.state.mobile ? null
              :
              this.state.currentTab === 0 ?
                <img alt='swirl' style={{ height: '80vh', width: '32vw' }} src={require('./assets/swirl.png')} />
                :
                null
          }
        </div>
        {/* Routes */}
        <Router>
          <Routes>
            <Route exact path='/' element={
              <LandingPage mobile={this.state.mobile} currentTab={this.state.mobile ? 0 : this.state.currentTab} updateCurrentTab={updateCurrentTab} />
            } />
            <Route exact path='services' element={
              <Services mobile={this.state.mobile} currentTab={this.state.mobile ? 0 : this.state.currentTab} updateCurrentTab={updateCurrentTab} />
            } />
            <Route exact path='products' element={
              <Products mobile={this.state.mobile} currentTab={this.state.mobile ? 0 : this.state.currentTab} updateCurrentTab={updateCurrentTab} />
            } />
            <Route exact path='mission' element={
              <Mission mobile={this.state.mobile} currentTab={this.state.mobile ? 0 : this.state.currentTab} updateCurrentTab={updateCurrentTab} />
            } />
            <Route exact path='contact' element={
              <Contact mobile={this.state.mobile} currentTab={this.state.mobile ? 0 : this.state.currentTab} updateCurrentTab={updateCurrentTab} />
            } />
            <Route exact path='login' element={
              <Login mobile={this.state.mobile} />
            } />
            <Route exact path='signUp' element={
              <SignUp mobile={this.state.mobile} />
            } />
            <Route exact path='account' element={
              <Account mobile={this.state.mobile} />
            } />
            <Route exact path='/myprofile' element={
              <Profile mobile={this.state.mobile} />
            } />
            <Route exact path='/mydeals' element={
              <Deals mobile={this.state.mobile} />
            } />
            <Route exact path='/myorderhistory' element={
              <OrderHistory mobile={this.state.mobile} />
            } />
            <Route exact path='/checkoutSuccess' element={
              <CheckoutSplash type='success' mobile={this.state.mobile} />
            } />
            <Route exact path='/checkoutCancel' element={
              <CheckoutSplash type='cancel' mobile={this.state.mobile} />
            } />
          </Routes>
        </Router>
      </div>
    );
  }
}
