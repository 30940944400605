import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Footer } from '../universalComponents';
import { ChevronLeft } from '@mui/icons-material';
import quickLinks from '../../utils/quickLinks';
const { openLocalSlug } = quickLinks();

const KADENTRADING_API_URL = 'https://api.kadentrading.com/api';

export default class Deals extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      deals: []
    };
  }

  grabDeals = async (token) => {
    try {
      const { data } = await axios.get(`${KADENTRADING_API_URL}/deals?limit=100&skip=0&sort=dealID&direction=ascending&user=${token}`);
      this.setState({ deals: data });
    } catch (err) {
      console.log(err);
    }
  }
  componentDidMount() {
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      if (c.includes('user=')) {
        const obj = c.replace('user=', '').trim();
        const userObj = JSON.parse(obj);
        if (userObj.token && userObj.dealIDs?.length > 0) {
          this.grabDeals(userObj.token);
        }
      }
    }
  }

  render() {
    const { mobile } = this.props;

    const linkHome = () => {
      openLocalSlug('/');
    }
    return (
      <div style={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
        <div style={{ flex: '0 1 auto' }}>
          <div onClick={linkHome} style={{ cursor: 'pointer' }}>
            <img alt='logo' style={{ width: '200px' }} src={require('../../assets/full_logo.png')} />
          </div>
        </div>
        <div style={{ flex: '1 1 auto', marginTop: mobile ? '3vh' : '13vh', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
          <div onClick={() => {openLocalSlug('/account')}} style={{
            position: 'absolute',
            top: '3vh',
            left: mobile ? '15vw' : '30vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '74px',
            borderRadius: '37px',
            border: '1px solid black',
            paddingRight: '5px',
            cursor: 'pointer',
            ':hover': { backgroundColor: 'rgba(0,0,0,0.2)' }
          }}><ChevronLeft />Back</div>
          <div style={{ fontSize: '35pt', fontWeight: 'bold', textAlign: 'center', marginBottom: '3vh', marginTop: mobile ? '10vh' : null }}>My Deals</div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            border: '2px solid black',
            padding: '4vh',
            borderRadius: '10px',
            width: mobile ? '60vw' : '40vw',
            backgroundColor: 'white',
            height: '45vh',
            overflowY: 'auto'
          }}>
            {
              this.state.deals.length > 0 ?
              this.state.deals.map((deal, dealIdx) => {
                return (
                  <div
                    key={`deal${dealIdx}`}
                    style={{
                      border: '2px solid black',
                      maxHeight: mobile ? '225px' : '180px',
                      width: mobile ? '28vw' : '10vw',
                      borderRadius: '10px',
                      padding: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      backgroundColor: '#0D95F2',
                      color: 'white',
                      marginBottom: '15px'
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                      <span style={{ letterSpacing: 2, fontWeight: 450 }}>{deal.dealID}</span>
                      <span style={{ color: 'rgba(255,255,255,0.85)', fontSize: '10pt' }}>Deal ID</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{ letterSpacing: 2, fontWeight: 450 }}>{deal.name}</span>
                      <span style={{ color: 'rgba(255,255,255,0.85)', fontSize: '10pt', marginBottom: '10px' }}>Deal Name</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{ letterSpacing: 2, fontWeight: 450 }}>{deal.type === '$' ? '$' : null}{deal.value || '--'}{deal.type === '%' ? '%' : null}</span>
                      <span style={{ color: 'rgba(255,255,255,0.85)', fontSize: '10pt', marginBottom: '10px' }}>Value</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{ letterSpacing: 2, fontWeight: 450 }}>{deal.expirationDate ? dayjs(deal.expirationDate).format('MM/DD/YYYY') : '--'}</span>
                      <span style={{ color: 'rgba(255,255,255,0.85)', fontSize: '10pt', marginBottom: '10px' }}>Expiration Date</span>
                    </div>
                  </div>
                )
              })
              :
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                No deals at this moment
              </div>
            }
          </div>
        </div>
        <div style={{ flex: '0 1 auto' }}>
          <Footer />
        </div>
        {/* <div style={{
          width: mobile ? '10vw' : '4vw',
          position: 'absolute',
          top: 0,
          left: mobile ? '5vw' : '2vw',
          bottom: 0,
          background: 'linear-gradient(to right, #0D95F2 0%, #0D95F2 33%, #F80871 33%, #F80871 66%, #FFE001 66%, #FFE001 100%)',
          zIndex: -2
        }} /> */}
      </div>
    )
  }
}
