import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';

const KADENTRADING_API_URL = 'https://api.kadentrading.com/api';

export default class FilterList extends Component {
    constructor(props) {
      super(props);
      this.props = props;
      this.state = {
        filters: [],
      };
    }

    getFilters = async () => {
        const { data } = await axios.get(`${KADENTRADING_API_URL}/filters`);
        const newFilters = data.map(filter => {
            filter.values = filter.values.filter(val => !!val.trim());
            return filter;
        })
        this.setState({ filters: newFilters });
    }

    componentDidMount() {
        this.getFilters();
    }

    render() {
        const { updateFilters, filters, mobile } = this.props;

        const updateFilter = (key, val) => {
            let newFilters = []
            if (!filters.map(f => f.val).includes(val)) {
                newFilters = filters.filter(f => f.key !== key);
                newFilters = [...newFilters, { key, val }];
            } else {
                newFilters = filters.filter(f => f.val !== val);
            }
            updateFilters(newFilters);
        }

        return (
            <div style={{ marginLeft: mobile ? null : '3vw'  }}>
                {
                    this.state.filters.map(filterObj => {
                        return (
                            <div key={`filter_${filterObj.title}`} style={{ marginBottom: '2vh' }}>
                                <fieldset style={{ marginBottom: 'large', borderColor: 'black', borderWidth: '1px' }}>
                                    <legend style={{ paddingLeft: '3px', paddingRight: '3px' }}>
                                        {filterObj.title === 'Categories' ? 'Filter' : filterObj.title}
                                    </legend>
                                    <FormGroup>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            { filterObj.values.map(val => {
                                                return (
                                                    <FormControlLabel key={val} control={
                                                        <Checkbox
                                                            sx={{ color: '#2273F2' }}
                                                            onClick={() => {updateFilter(filterObj.key, val)}}
                                                            checked={filters.map(f => f.val).includes(val)}
                                                        />
                                                    } label={val} />
                                                )
                                            }) }
                                        </div>
                                    </FormGroup>
                                </fieldset>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}
