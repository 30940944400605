import { FilterList as FilterIcon, Search } from '@mui/icons-material';
import { Button, CircularProgress, Dialog, Divider, FormControl, ImageList, ImageListItem, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import React, { Component, createRef } from 'react';
import { ReactComponent as GGDeal } from '../../assets/ggDeal.svg';
import { Footer, Tabs } from '../universalComponents';
import FilterList from './components/filterList';
import { FilterDialog, ProductDetailsDialog } from './dialogs';

const KADENTRADING_API_URL = 'https://api.kadentrading.com/api';

export default class Products extends Component {
    constructor(props) {
      super(props);
      this.listInnerRef = createRef()  
      this.props = props;
      this.state = {
        limit: 24,
        skip: 0,
        sort: 'map',
        direction: 'decending',
        products: [],
        loading: false,
        search: null,
        filters: [],
        sortVal: 'map decending',
        product: null,
        productDialog: false,
        filtersDialog: false,
        dealIDs: [],
        cartItems: [],
        cartDeals: []
      };
    }

    grabProducts = async (filters, search, skipVal, sortVal, directionVal) => {
      try {
        const { limit, skip, sort, direction } = this.state;
        const theSkipVal = skipVal ? skipVal : skip;

        let url = `${KADENTRADING_API_URL}/products?limit=${limit}&skip=${theSkipVal}&sort=${sortVal ? sortVal : sort}&direction=${directionVal ? directionVal : direction}`;
        if (this.state.search || search) url = url + `&search=${this.state.search || search}`;
        if (filters) {
          filters.forEach(fil => {
            url = url + `&${fil.key}=${fil.val}`;
          })
        } else if (this.state.filters.length) {
          this.state.filters.forEach(fil => {
            url = url + `&${fil.key}=${fil.val}`;
          })
        }

        const { data } = await axios.get(url);
        if (data.length > 0) {
          const uniqueArray = [...this.state.products, ...data].filter((value, index) => {
            const _value = JSON.stringify(value);
            return index === [...this.state.products, ...data].findIndex(obj => {
              return JSON.stringify(obj) === _value;
            });
          });
          this.setState({ products: uniqueArray, skip: Number(theSkipVal) + 1, loading: false });
        } else if (Number(theSkipVal) > 0) {
          this.setState({ loading: false });
        } else {
          this.setState({ products: [], skip: 0, loading: false });
        }
      } catch (err) {
        console.log(err);
      }
    }
    grabDeals = async (token) => {
      try {
        const { data } = await axios.get(`${KADENTRADING_API_URL}/deals?limit=100&skip=0&sort=dealID&direction=ascending&user=${token}`);
        this.setState({ dealIDs: data });
      } catch (err) {
        console.log(err);
      }
    }
    handleScroll = event => {
      if (event.target.scrollHeight - event.target.scrollTop < 800 && !this.state.loading) {
        this.setState({ loading: true });
        this.grabProducts();
      }
    }

    componentDidMount() {
      const { updateCurrentTab, mobile } = this.props;
      if (!mobile) updateCurrentTab(1);
      
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        if (c.includes('user=')) {
          const obj = c.replace('user=', '').trim();
          const userObj = JSON.parse(obj);
          if (userObj.token && userObj.dealIDs?.length > 0) {
            this.grabDeals(userObj.token);
          }
        } else if (c.includes('cartItems=')) {
          const array = c.replace('cartItems=', '').trim();
          const cartItems = JSON.parse(array);
          if (cartItems.length > 0) this.setState({ cartItems });
        }
      }

      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.search) {
        this.setState({ loading: true, search: params.search });
        this.grabProducts(null, params.search);
      } else this.grabProducts();

      const element = document.getElementById('imageList');
      element.addEventListener('scroll', this.handleScroll, true);
    }
    componentWillUnmount() {
      const element = document.getElementById('imageList');
      element.removeEventListener('scroll', this.handleScroll);
    }

    render() {
      const { currentTab, mobile } = this.props;
      const updateFilters = (filters) => {
        this.setState({ filters, products: [], skip: 0, loading: true });
        this.grabProducts(filters, null, '0');
      }
      const toggleFiltersDialog = () => {
        this.setState({ filtersDialog: !this.state.filtersDialog });
      }
      const handleSort = (event) => {
        const eventSplit = event.target.value.split(' ');
        this.setState({ sortVal: event.target.value, products: [], skip: 0, loading: true, sort: eventSplit[0], direction: eventSplit[1] });
        this.grabProducts(null, null, '0', eventSplit[0], eventSplit[1]);
      }
      const setActiveProduct = (product) => {
        this.setState({ product, productDialog: !this.state.productDialog });
      }
      const addToCart = (product, qty) => {
        const newProductArray = qty ? [...Array(qty).keys()].map(val => product) : [product];
        const productDeals = this.state.dealIDs.filter(deal => deal.productID === product.sku);
        const stateObj = { cartItems: [...this.state.cartItems, ...newProductArray] };
        if (productDeals.length > 0) {
          stateObj['cartDeals'] = [...this.state.cartDeals, productDeals[0]]
          const encodedDeal = encodeURI(JSON.stringify([...this.state.cartDeals, productDeals[0]]));
          document.cookie = `cartDeals=${encodedDeal}`;
        }

        const encodedItems = encodeURI(JSON.stringify([...this.state.cartItems, ...newProductArray]));
        document.cookie = `cartItems=${encodedItems}`;
        this.setState(stateObj);
      }
      const calcDeal = (product) => {
        let discountPrice = product.map;
        const index = this.state.dealIDs.map(deal => deal.productID).indexOf(product.sku);
        const deal = this.state.dealIDs[index];
        if (deal.type === '$') {
          discountPrice = discountPrice - deal.value;
        } else {
          discountPrice = discountPrice - (discountPrice * (deal.value/100));
        }
        return discountPrice.toFixed(2);
      }
      const updateCartItemsAndDeals = ({ cartItems, cartDeals }) => {
        this.setState({ cartItems, cartDeals: cartDeals || [] });
      }
      return (
        <div style={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
          {/* Navigation */}
          <div style={{ flex: '0 1 auto' }}>
            <Tabs
              updateCartItemsAndDeals={updateCartItemsAndDeals}
              cartItems={this.state.cartItems}
              cartDeals={this.state.cartDeals}
              currentTab={currentTab}
              mobile={mobile}
            />
          </div>
          {/* Main Content */}
          <div style={{ flex: '0 1 auto', flexDirection: mobile ? 'column ' : 'row', display: 'flex' }}>
            <TextField
              sx={{
                fontStyle: 'italic', marginTop: '2vh', height: '55px',
                width: mobile ? '90%' : '45vw', marginLeft: mobile ? 'auto' : '3vw', marginRight: mobile ? 'auto' : null,
                '& label.Mui-focused': { color: 'black' },
                '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: 'black' }, '& fieldset': { borderColor: 'black' }, borderRadius: '5px' }
              }}
              label='Search by brand or printer model'
              InputLabelProps={{ style: { paddingRight: '5px' } }}
              variant='outlined'
              value={this.state.search || ''}
              onChange={(e) => {this.setState({ search: e.target.value })}}
              onKeyDown={(e) => {
                if(e.key === 'Enter') {
                  this.setState({ loading: true, search: e.target.value, skip: 0, products: [] });
                  this.grabProducts(null, e.target.value, '0');
                }
              }}
              InputProps={ mobile ? {
                startAdornment: (
                  <InputAdornment style={{ cursor: 'pointer' }} onClick={() => {
                    this.setState({ loading: true, skip: 0, products: [] });
                    this.grabProducts(null, this.state.search, '0');
                  }} position='start'>
                    <Search />
                  </InputAdornment>
                )
              } : {
                endAdornment: (
                  <InputAdornment style={{ cursor: 'pointer' }} onClick={() => {
                    this.setState({ loading: true, skip: 0, products: [] });
                    this.grabProducts(null, this.state.search, '0');
                  }} position='end'>
                    <Search />
                  </InputAdornment>
                )
              }}
            />
            <div style={{ width: mobile ? '90%' : null, marginLeft: mobile ? 'auto' : null, marginRight: mobile ? 'auto' : null }}>
              {
                mobile ?
                <Button
                  sx={{
                    color: 'black',
                    '&.MuiButton-root': { border: '1px black solid' }
                  }}
                  startIcon={<FilterIcon />}
                  style={{ width: '42vw', marginRight: '6vw', marginTop: '2vh', height: '55px' }}
                  variant='outlined'
                  onClick={toggleFiltersDialog}
                >Filters</Button>
                : null
              }
              <FormControl
                variant='outlined'
                style={{
                  marginRight: mobile ? null : 'auto',
                  marginLeft: mobile ? null : '15vw',
                  marginTop: '2vh',
                  width: mobile ? '42vw' : '15vw',
                  backgroundColor: 'white',
                  borderRadius: '5px',
                  height: '55px'
                }}
              >
                <InputLabel sx={{ color: 'black' }}>Sort</InputLabel>
                <Select
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'black',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'black',
                    }
                  }}
                  value={this.state.sortVal}
                  label='Sort'
                  onChange={handleSort}
                >
                  <MenuItem value='map ascending'>Price: Low to High</MenuItem>
                  <MenuItem value='map decending'>Price: High to Low</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div
            style={{
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginTop: '4vh',
              height: '70vh'
            }}
          >
            { !mobile ?
              <div style={{ width: '18vw' }}>
                <FilterList filters={this.state.filters} mobile={mobile} updateFilters={updateFilters} />
              </div>
              : null
            }
            <div
              style={{
                marginLeft: '2vw',
                marginRight: mobile ? '2vw' : 'auto',
                width: '100%',
                display: mobile ? 'flex' : null,
                justifyContent: mobile ? 'center' : null
              }}>
              <ImageList id='imageList' cols={1} gap={10}
                sx={{
                  marginTop: 0,
                  '::-webkit-scrollbar': { display: 'none' },
                  width: (this.state.loading && !this.state.products.length) || '95%',
                  height: '95%'
                }}
              >
              {
                this.state.loading && !this.state.products.length ?
                <div style={{
                  color: '#90EE90',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}>
                  <CircularProgress size={75} color='inherit' />
                </div>
                : this.state.products.length ?
                this.state.products.map((product, pIdx) => (
                  <div key={`${product.productName}${pIdx}`}>
                    <ImageListItem style={{ border: '1px solid rgba(0,0,0,0.1)', padding: '10px', borderRadius: '10px' }}>
                      {
                        mobile ?
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <div
                            style={{
                              maxHeight: '200px',
                              height: '200px',
                              width: '225px',
                              padding: '20px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            {
                              product.url ?
                              <img alt={product.productName} style={{ width: 'auto', height: 'auto', maxHeight: '125px', maxWidth: '225px' }} src={product.url} />
                              :
                              <img alt='ImagePlaceholder' style={{ width: '125px' }} src={require('../../assets/imagePlaceholder.png')} />
                            }
                          </div>
                          <Divider sx={{ width: '100%', marginBottom: '10px' }} />
                          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
                              <span style={{ fontSize: '13pt', marginBottom: null }}>{product.productName}</span>
                              <span style={{ fontSize: '10pt', color: '#8F0000' }}>{product.productType}</span>
                              <span style={{ marginBottom: 'auto', fontSize: '10pt', color: 'rgba(0,0,0,0.5)' }}>by {product.brand}</span>
                              {
                                this.state.dealIDs.filter(deal => !!deal.productID).map(deal => deal.productID).includes(product.sku) ?
                                <div style={{ display: 'flex', marginBottom: '10px', marginTop: '10px' }}>
                                  <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                                    <span style={{ fontSize: '10pt', color: 'rgba(0,0,0,0.5)' }}>With deal:</span>
                                    <span style={{ fontSize: '14pt', textDecoration: 'line-through', textDecorationColor: 'rgba(0,0,0,0.25)' }}>
                                      $ {product.map}
                                    </span>
                                  </div>
                                  <GGDeal style={{ marginLeft: '5px', marginRight: '5px' }} width='35px' height='35px' />
                                  <span style={{ fontSize: '14pt', color: '#00CB3D', marginTop: 'auto' }}>$ {calcDeal(product)}</span>
                                </div>
                                :
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', marginTop: '10px' }}>
                                  <span style={{ fontSize: '10pt', color: 'rgba(0,0,0,0.5)' }}>Starting at:</span>
                                  <span style={{ fontSize: '14pt' }}>{product.map ? `$ ${product.map}` : '--'}</span>
                                </div>
                              }
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '15px', width: '35%' }}>
                            {
                              product.compatiblePrinters ?
                              <div style={{ display: 'flex', flexDirection: 'column', width: '27vw' }}>
                                <div style={{ marginTop: 'auto', display: 'flex', flexDirection: 'column' }}>
                                  <span style={{ fontSize: '11pt', marginBottom: null }}>Compatible Printers</span>
                                  <span style={{ fontSize: '10pt', color: '#8F0000' }}>
                                    {product.compatiblePrinters.slice(0, 4).map((printer, pIdx) => 
                                      <div key={`printer${pIdx}_${product.sku}`}>
                                        <span>- {printer}{pIdx === 3 && product.compatiblePrinters[pIdx + 1] ? '...' : null}</span><br />
                                      </div>
                                    )
                                    }
                                  </span>
                                </div>
                              </div>
                              : null
                            }
                            </div>
                          </div>
                          <Button
                            variant='contained'
                            sx={{
                              bgcolor: '#0D95F2',
                              '&:hover': { 'bgcolor': '#2273F2' },
                              color: 'white',
                              marginTop: 'auto',
                              width: '100%'
                            }}
                            onClick={() => {setActiveProduct(product)}}
                          >View Details</Button>
                          <Button
                            variant='contained'
                            sx={{
                              bgcolor: '#0D95F2',
                              '&:hover': { 'bgcolor': '#2273F2' },
                              color: 'white',
                              marginTop: '10px',
                              width: '100%'
                            }}
                            onClick={() => {addToCart(product)}}
                          >Quick Add</Button>
                        </div>
                        :
                        <div style={{ display: 'flex' }}>
                          <div style={{ maxHeight: '125px', width: '125px', padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {
                              product.url ?
                              <img alt={product.productName} style={{ width: 'auto', height: 'auto', maxHeight: '125px', maxWidth: '125px' }} src={product.url} />
                              :
                              <img alt='ImagePlaceholder' style={{ width: '150px' }} src={require('../../assets/imagePlaceholder.png')} />
                            }
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontSize: '14pt', marginBottom: null }}>{product.productName}</span>
                              <span style={{ fontSize: '11pt', color: '#8F0000' }}>{product.productType}</span>
                              <span style={{ marginBottom: 'auto', fontSize: '11pt', color: 'rgba(0,0,0,0.5)' }}>by {product.brand}</span>
                              {
                                this.state.dealIDs.filter(deal => !!deal.productID).map(deal => deal.productID).includes(product.sku) ?
                                <div style={{ display: 'flex', marginBottom: '10px', marginTop: '10px' }}>
                                  <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                                    <span style={{ fontSize: '12pt', color: 'rgba(0,0,0,0.5)' }}>With deal:</span>
                                    <span style={{ fontSize: '17pt', textDecoration: 'line-through', textDecorationColor: 'rgba(0,0,0,0.25)' }}>
                                      $ {product.map}
                                    </span>
                                  </div>
                                  <GGDeal style={{ marginLeft: '10px', marginRight: '10px' }} width='45px' height='45px' />
                                  <span style={{ fontSize: '17pt', color: '#00CB3D', marginTop: 'auto' }}>$ {calcDeal(product)}</span>
                                </div>
                                :
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', marginTop: '10px' }}>
                                  <span style={{ fontSize: '12pt', color: 'rgba(0,0,0,0.5)' }}>Starting at:</span>
                                  <span style={{ fontSize: '17pt' }}>{product.map ? `$ ${product.map}` : '--'}</span>
                                </div>
                              }
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Button
                                  variant='contained'
                                  sx={{
                                    bgcolor: '#0D95F2',
                                    '&:hover': { 'bgcolor': '#2273F2' },
                                    color: 'white',
                                    width: '7vw',
                                    marginTop: 'auto',
                                    marginRight: '10px',
                                    fontSize: '8pt'
                                  }}
                                  onClick={() => {setActiveProduct(product)}}
                                >View Details</Button>
                                <Button
                                  variant='contained'
                                  disabled={!product.map}
                                  sx={{
                                    bgcolor: '#0D95F2',
                                    '&:hover': { 'bgcolor': '#2273F2' },
                                    color: 'white',
                                    width: '7vw',
                                    marginTop: 'auto',
                                    fontSize: '8pt'
                                  }}
                                  onClick={() => {addToCart(product)}}
                                >Quick Add</Button>
                              </div>
                            </div>
                            {
                              product.compatiblePrinters ?
                              <div style={{ display: 'flex', flexDirection: 'column', width: '27vw' }}>
                                <div style={{ marginTop: 'auto', display: 'flex', flexDirection: 'column' }}>
                                  <span style={{ marginBottom: null }}>Compatible Printers</span>
                                  <span style={{ fontSize: '11pt', color: '#8F0000' }}>
                                    {product.compatiblePrinters.slice(0, 5).map((printer, pIdx) => 
                                      <div key={`printer${pIdx}_${product.sku}`}>
                                        <span>- {printer}{pIdx === 4 && product.compatiblePrinters[pIdx + 1] ? '...' : null}</span><br />
                                      </div>
                                    )
                                    }
                                  </span>
                                </div>
                              </div>
                              : null
                            }
                          </div>
                        </div>
                      }
                    </ImageListItem>
                  </div>
                ))
                :
                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <span style={{ fontSize: '14pt' }}>
                    No Products to Display
                  </span>
                </div>
              }
              </ImageList>
            </div>
          </div>
          {/* Footer */}
          <div style={{ flex: '0 1 auto' }}><Footer /></div>
          {/* Dialogs */}
          <Dialog open={this.state.filtersDialog} onClose={toggleFiltersDialog} fullWidth maxWidth='md'>
            <FilterDialog updateFilters={updateFilters} filters={this.state.filters} close={toggleFiltersDialog} />
          </Dialog>
          <Dialog open={this.state.productDialog} onClose={setActiveProduct} fullWidth maxWidth='md'>
            <ProductDetailsDialog addToCart={addToCart} product={this.state.product} mobile={mobile} setActiveProduct={setActiveProduct} />
          </Dialog>
        </div>
      )
    }
}
