import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle
} from '@mui/material';
import { VpnKey, VpnKeyOff } from '@mui/icons-material';
import quickLinks from '../../utils/quickLinks';
import { Buffer } from 'buffer';
import axios from 'axios';

const KADENTRADING_API_URL = 'https://api.kadentrading.com/api';

const LoginForm = () => {
  const { openLocalSlug } = quickLinks();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, toggleLoading] = useState(false);
  const [loadingDialog, toggleLoadingDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');

  const redirect = () => {
    openLocalSlug('/');
  }
  const login = async () => {
    const token = Buffer.from(`${email}:${password}`).toString('base64');
    try {
      const { data } = await axios.post(
        `${KADENTRADING_API_URL}/login`,
        { email },
        { headers: { 'Authorization': `Basic ${token}` } }
      );

      if (data) {
        document.cookie = `user=${JSON.stringify(data)}`;
      }
      redirect();
    } catch (err) {
      toggleLoading(false);
      if (err.response.data?.errorMessage) {
        setDialogText(err.response.data.errorMessage);
      } else setDialogText('Something went wrong. Please check your email and password, then try again.');
    }
  }
  const submitForm = () => {
    setDialogText('Processing...');
    toggleLoading(true);
    toggleLoadingDialog(true);
    login();
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <TextField
            fullWidth
            autoComplete='username'
            type='email'
            value={email}
            onChange={(e) => {setEmail(e.target.value)}}
            label='Email Address'
          />
          <TextField
            fullWidth
            autoComplete='current-password'
            value={password}
            onChange={(e) => {setPassword(e.target.value)}}
            type={showPassword ? 'text' : 'password'}
            label='Password'
            onKeyDown ={(event) => {
              if (event.key === 'Enter') {
                submitForm();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? (
                      <VpnKey />
                    ) : (
                      <VpnKeyOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Stack spacing={3} sx={{ mt: 3 }}>
          <Button
            fullWidth
            disabled={!email || !password}
            variant='contained'
            onClick={submitForm}
            sx={{ bgcolor: '#0D95F2' }}
          >Login</Button>
        </Stack>
        <Box>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={{ my: 2 }}
          >
            <Link
              component={RouterLink}
              variant='subtitle2'
              to='#'
              underline='hover'
            >
              Forgot password?
            </Link>
          </Stack>
        </Box>
      </Box>
      <Dialog open={loadingDialog} onClose={() => {toggleLoadingDialog(false)}} fullWidth maxWidth='sm'>
        <DialogTitle>Logging In</DialogTitle>
        <DialogContent>
          <Typography variant='subtitle1' component='div'>
            {dialogText}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ bgcolor: '#0D95F2' }}
            variant='contained'
            disabled={loading}
            onClick={() => {toggleLoadingDialog(false)}}
          >Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoginForm;
