import { Email } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import quickLinks from '../../utils/quickLinks';

const Footer = () => {
  const { openMail } = quickLinks();

  return (
    <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center' }}>
      <div style={{ color: 'black', display: 'flex', paddingBottom: '5px' }}>
        <img alt='logo' style={{ width: '120px' }} src={require('../../assets/full_logo.png')} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', fontSize: '6pt', justifyContent: 'center' }}>
        <div>Kaden Trading Company</div>
        <div>4045 N Rockwell St</div>
        <div>Unit 108</div>
        <div>Chicago, IL 60618</div>
      </div>
      <IconButton
        sx={{ '&:hover': { backgroundColor: 'rgba(0,0,0,0.2)' }, marginTop: 'auto', marginBottom: 'auto', color: '#000000' }}
        onClick={openMail}
        variant='contained'
      ><Email /></IconButton>
    </div>
  )
}

export default Footer;
