import { LightbulbOutlined, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import React, { Component, createRef } from 'react';
import quickLinks from '../../utils/quickLinks';
import { Footer, Tabs } from '../universalComponents';

export default class MainContent extends Component {
    constructor(props) {
      super(props);
      this.infoGraphic = createRef()  
      this.props = props;
      this.state = {
        search: ''
      };
    }

    executeScroll = () => this.infoGraphic.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

    render() {
      const { currentTab, mobile } = this.props;
      const { openLocalSlug } = quickLinks();

      const quickLink = (search) => {
        openLocalSlug(`/products?search=${search}`);
      }
      const setSearch = (text) => {
        this.setState({ search: text });
      }

      return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {/* Navigation */}
          <div style={{ flex: '0 1 auto' }}>
            <Tabs currentTab={currentTab} mobile={mobile} />
          </div>
          {/* Main Content */}
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '1 1 auto' }}>
            <div style={{ marginTop: '10vh', fontSize: mobile ? '30pt' : '45pt', fontWeight: 'bold', textAlign: 'center', marginLeft: mobile ? '17vw' : '8vw' }}>PRINT ON,</div>
            <div style={{ marginTop: '2vh', fontSize: mobile ? '30pt' : '45pt', fontWeight: 'bold', textAlign: 'center', marginLeft: mobile ? '17vw' : '8vw' }}>AFFORDABLY.</div>
            <div style={{ marginTop: '8vh', display: 'flex', flexDirection: 'column', marginLeft: '8vw' }}>
              <TextField
                sx={{
                  alignSelf: mobile ? null : 'center', width: mobile ? '75vw' : '45vw', marginLeft: mobile ? '9vw' : null, fontStyle: 'italic',
                  '& label.Mui-focused': { color: 'black' },
                  '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: 'black' }, '& fieldset': { borderColor: 'black' }, borderRadius: '25px', backgroundColor: 'white' }
                }}
                label='Search by brand or printer model'
                InputLabelProps={{ style: { paddingRight: '5px' } }}
                variant='outlined'
                onChange={(e) => {setSearch(e.target.value)}}
                onKeyDown={(e) => { if(e.key === 'Enter') quickLink(this.state.search) }}
                InputProps={{
                 endAdornment: (
                   <InputAdornment style={{ cursor: 'pointer' }} onClick={() => {quickLink(this.state.search)}} position='end'>
                     <Search />
                   </InputAdornment>
                 )
                }}
              />
              <div style={{ marginTop: '2vh', alignSelf: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {
                    mobile ? null :
                    <>
                      <Tooltip title={<div style={{ textAlign: 'center', fontSize: '10pt' }}>
                        To find the cartridge number, remove the cartridge from the printer.
                        Look on the top or side of the cartridge for a combination of numbers and/or letters.
                        To find the printer number, the model name and series number are usually on the front of the printer.
                      </div>}>
                        <IconButton
                          sx={{ '&:hover': { backgroundColor: 'rgba(0,0,0,0.2)' }, marginTop: 'auto', marginBottom: 'auto', color: '#000000' }}
                          variant='contained'
                        ><LightbulbOutlined /></IconButton>
                      </Tooltip>
                      <div style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '5px', fontSize: mobile ? '10pt' : 'inherit' }}>Where to find the cartridge or printer number</div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          {/* Footer */}
          <div style={{ flex: '0 1 auto' }}><Footer /></div>
          {/* Graphics */}
          {
            mobile ?
            <div style={{
              width: '10vw',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: '5vw',
              background: 'linear-gradient(to right, #0D95F2 0%, #0D95F2 33%, #F80871 33%, #F80871 66%, #FFE001 66%, #FFE001 100%)',
              zIndex: -2
            }} />
            : null
          }
        </div>
      )
    }
}
