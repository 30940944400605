import React, { Component } from 'react';
import { Divider } from '@mui/material';
import { Tabs, Footer } from '../universalComponents';

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  componentDidMount() {
    const { updateCurrentTab, mobile } = this.props;
    if (!mobile) updateCurrentTab(3);
  }

  render() {
    const { currentTab, mobile } = this.props;
    return (
      <div style={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
        <div style={{ flex: '0 1 auto' }}>
          <Tabs currentTab={currentTab} mobile={mobile} />
        </div>
        <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'row', justifyContent: mobile ? 'center' : 'flex-start', marginTop: '7vh', marginLeft: mobile ? null : '5vw' }}>
          <div style={{ width: '100%', fontSize: mobile ? '14pt' : '16pt', display: 'flex', flexDirection: 'column', textAlign: 'center', paddingLeft: '16vw', paddingRight: '16vw' }}>
            <div style={{ fontSize: mobile ? '25pt' : '35pt', fontWeight: 'bold', textAlign: 'center' }}>Have a question?</div>
            <Divider sx={{ bgcolor: '#F32028', marginY: '20px', width: mobile ? '65vw' : '27vw', alignSelf: 'center', borderBottomWidth: 2 }} />
            <p style={{ marginTop: '35px', alignSelf: 'center' }}>
              We are happy to answer any questions or concerns you might have. We guarantee a response within 24 hours.
            </p>
            <p style={{ marginBottom: 0, marginTop: '35px', alignSelf: 'center' }}>Please feel free to email us</p>
            <p style={{ marginTop: 0, alignSelf: 'center' }}>at<a style={{ color: 'black', marginLeft: '5px', fontWeight: 'bold' }} href='mailto:notifications@kadentrading.com'>notifications@kadentrading.com.</a></p>
            <div style={{ display: 'flex', flexDirection: 'column', fontSize: '10pt', marginTop: '5px' }}>
              <div>Kaden Trading Company</div>
              <div>4045 N Rockwell St</div>
              <div>Unit 108</div>
              <div>Chicago, IL 60618</div>
            </div>
          </div>
        </div>
        <div style={{ flex: '0 1 auto' }}>
          <Footer />
        </div>
        {/* <div style={{
          width: mobile ? '10vw' : '4vw',
          position: 'absolute',
          top: 0,
          left: mobile ? '5vw' : '2vw',
          bottom: 0,
          background: 'linear-gradient(to right, #0D95F2 0%, #0D95F2 33%, #F80871 33%, #F80871 66%, #FFE001 66%, #FFE001 100%)',
          zIndex: -2
        }} /> */}
      </div>
    )
  }
}
