import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { Footer } from '../universalComponents';
import quickLinks from '../../utils/quickLinks';
const { openLocalSlug } = quickLinks();

export default function Account(props) {
  const { mobile, type } = props;

  useEffect(() => {
    if (type === 'success') {
      document.cookie = 'cartItems=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
      document.cookie = 'cartDeals=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    }
  });

  const linkHome = () => {
    openLocalSlug('/');
  }
  const linkProducts = () => {
    openLocalSlug('/products');
  }
  const linkContact = () => {
    openLocalSlug('/contact');
  }

  return (
    <div style={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
      <div style={{ flex: '0 1 auto' }}>
          <div onClick={linkHome} style={{ cursor: 'pointer' }}>
            <img alt='logo' style={{ width: '200px' }} src={require('../../assets/full_logo.png')} />
          </div>
      </div>
      <div style={{ flex: '1 1 auto', marginTop: mobile ? '3vh' : '13vh', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
        {
          type === 'success' ?
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ fontSize: '35pt', fontWeight: 'bold', textAlign: 'center', marginBottom: '3vh', padding: '30px' }}>
              Thanks for shopping with us!
            </div>
            <Button
              variant='contained'
              sx={{ bgcolor: '#0D95F2', '&:hover': { 'bgcolor': '#2273F2' }, color: 'white', width: mobile ? '60vw' : '30vw' }}
              onClick={linkProducts}
            >Continue Shopping</Button>
          </div>
          :
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ fontSize: '35pt', fontWeight: 'bold', textAlign: 'center', marginBottom: '3vh', padding: '30px' }}>
            Looks like you've cancelled your checkout.
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                variant='contained'
                sx={{ bgcolor: '#0D95F2', '&:hover': { 'bgcolor': '#2273F2' }, color: 'white', width: mobile ? '30vw': '15vw', marginRight: '20px' }}
                onClick={linkProducts}
              >Continue Shopping</Button>
              <Button
                variant='outlined'
                sx={{ border: '1px solid #0D95F2', '&:hover': { 'bgcolor': 'rgba(13,149,242,0.2)' }, color: '#0D95F2', width: mobile ? '30vw': '15vw' }}
                onClick={linkContact}
              >Contact Support</Button>
            </div>
          </div>
        }
      </div>
      <div style={{ flex: '0 1 auto' }}>
        <Footer />
      </div>
      {/* <div style={{
        width: mobile ? '10vw' : '4vw',
        position: 'absolute',
        top: 0,
        left: mobile ? '5vw' : '2vw',
        bottom: 0,
        background: 'linear-gradient(to right, #0D95F2 0%, #0D95F2 33%, #F80871 33%, #F80871 66%, #FFE001 66%, #FFE001 100%)',
        zIndex: -2
      }} /> */}
    </div>
  )
}
