import React, { useState } from 'react';
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle
} from '@mui/material';
import { VpnKey, VpnKeyOff } from '@mui/icons-material';
import quickLinks from '../../utils/quickLinks';
import { Buffer } from 'buffer';
import axios from 'axios';

const KADENTRADING_API_URL = 'https://api.kadentrading.com/api';

const LoginForm = () => {
  const { openLocalSlug } = quickLinks();

  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, toggleLoading] = useState(false);
  const [loadingDialog, toggleLoadingDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const redirect = () => {
    openLocalSlug('/');
  }
  const createNewUser = async () => {
    const token = Buffer.from(`${email}:${password}`).toString('base64');
    try {
      const { data } = await axios.post(`${KADENTRADING_API_URL}/newUser`, {
        firstName,
        lastName,
        email
      }, { headers: { 'Authorization': `Basic ${token}` } });
  
      if (data) {
        document.cookie = `user=${JSON.stringify(data)}`;
      }
      redirect();
    } catch (err) {
      if (err.response.data?.errorMessage) setDialogContent(err.response.data.errorMessage);
      toggleLoading(false);
    }
  }
  const submitForm = () => {
    toggleLoading(true);
    setDialogContent('Creating your account.');
    toggleLoadingDialog(true);
    createNewUser();
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField value={firstName} onChange={(e) => {setFirstName(e.target.value)}} fullWidth label='First name' />
          <TextField value={lastName} onChange={(e) => {setLastName(e.target.value)}} fullWidth label='Last name' />
        </Stack>
        <Stack spacing={3}>
          <TextField
            value={email}
            onChange={(e) => {setEmail(e.target.value)}}
            fullWidth
            autoComplete='username'
            type='email'
            label='Email address'
          />
          <TextField
            value={password}
            onChange={(e) => {setPassword(e.target.value)}}
            fullWidth
            autoComplete='current-password'
            type={showPassword ? 'text' : 'password'}
            label='Password'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? (
                      <VpnKey />
                    ) : (
                      <VpnKeyOff
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack spacing={3}>
          <Button
            sx={{ bgcolor: '#0D95F2' }}
            fullWidth
            disabled={!firstName || !lastName || !email || !password}
            variant='contained'
            onClick={submitForm}
          >Sign Up</Button>
        </Stack>
      </Stack>
      <Dialog open={loadingDialog} onClose={() => {toggleLoadingDialog(false)}} fullWidth maxWidth='sm'>
        <DialogTitle>Signing Up</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" component="div">
            {dialogContent}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button sx={{ bgcolor: '#0D95F2' }} variant='contained' disabled={loading} onClick={() => {toggleLoadingDialog(false)}}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoginForm;
