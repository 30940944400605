import { Divider } from '@mui/material';
import React, { Component } from 'react';
import { ReactComponent as BulletPoint } from '../../assets/bulletPoint.svg';
import { Footer, Tabs } from '../universalComponents';

export default class Services extends Component {
  componentDidMount() {
    const { updateCurrentTab, mobile } = this.props;
    if (!mobile) updateCurrentTab(2);
  }

  render() {
    const { currentTab, mobile } = this.props;

    return (
      <div style={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
        {/* Navigation */}
        <div style={{ flex: '0 1 auto' }}>
          <Tabs currentTab={currentTab} mobile={mobile} />
        </div>
        {/* Main Content */}
        <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'row', justifyContent: mobile ? 'center' : 'flex-start', marginTop: '7vh', marginLeft: mobile ? null : '5vw' }}>
          <div style={{ width: mobile ? '90vw' : '45vw', textAlign: mobile ? 'center' : null, fontSize: mobile ? '14pt' : '16pt', paddingLeft: mobile ? '16vw' : null, paddingRight: '16vw' }}>
            <div style={{ fontSize: mobile ? '25pt' : '35pt', fontWeight: 'bold' }}>Interested in saving money?</div>
            <Divider sx={{ bgcolor: '#F32028', marginY: '20px', borderBottomWidth: 2 }} />
            <p style={{ marginTop: 0, marginBottom: '5px' }}>We offer a free consultation on how to get the lowest cost on your printing needs!</p>
            <p style={{ marginTop: '35px', marginBottom: '5px', textAlign: 'start' }}>We welcome business from:</p>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
              <BulletPoint style={{ marginTop: '3px' }} height='20px' width='20px' />
              <div style={{ marginLeft: '5px', textAlign: 'start' }}>schools districts</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
              <BulletPoint style={{ marginTop: '3px' }} height='20px' width='20px' />
              <div style={{ marginLeft: '5px', textAlign: 'start' }}>government agencies</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
              <BulletPoint style={{ marginTop: '3px' }} height='20px' width='20px' />
              <div style={{ marginLeft: '5px', textAlign: 'start' }}>local mom and pop shops</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
              <BulletPoint style={{ marginTop: '3px' }} height='20px' width='20px' />
              <div style={{ marginLeft: '5px', textAlign: 'start' }}>and anyone interested in printing more efficiently!</div>
            </div>
            <p style={{ marginTop: '35px', marginBottom: '5px' }}>
              Please email us at
              <br />
              <a style={{ marginLeft: '5px', marginRight: '5px', color: 'black', fontWeight: 'bold' }} href='mailto:notifications@kadentrading.com'>notifications@kadentrading.com</a>
              <br />
              with a short intro, and we will respond within 24 hours.
            </p>
          </div>
        </div>
        {/* Footer */}
        <div style={{ flex: '0 1 auto' }}><Footer /></div>
        {/* Graphics */}
        {/* {
          mobile ?
            <div style={{
              width: '10vw',
              position: 'absolute',
              top: 0,
              left: '5vw',
              bottom: 0,
              background: 'linear-gradient(to right, #0D95F2 0%, #0D95F2 33%, #F80871 33%, #F80871 66%, #FFE001 66%, #FFE001 100%)',
              zIndex: -2
            }} />
            :
            <>
              <img
                alt='fallingMoney'
                style={{ height: '43vh', width: '18vw', position: 'absolute', bottom: '8vh', right: '22vw' }}
                src={require('../../assets/fallingMoney.png')}
              />
              <img
                alt='hangingMan'
                style={{ height: '33vh', width: '14vw', position: 'absolute', bottom: '43vh', right: '10vw', rotate: '-26deg' }}
                src={require('../../assets/hangingMan.png')}
              />
            </>
        } */}
      </div>
    )
  }
}
