const fn = () => {
    const openMail = () => window.open('mailto:notifications@kadentrading.com');
    const openLocalSlug = (url) => window.location.replace(url);

    return {
        openMail,
        openLocalSlug,
    };
};

export default fn;
