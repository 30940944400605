import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Divider } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { Footer } from '../universalComponents';
import quickLinks from '../../utils/quickLinks';
const { openLocalSlug } = quickLinks();

const KADENTRADING_API_URL = 'https://api.kadentrading.com/api';

export default class OrderHistory extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      history: []
    };
  }

  grabHistory = async (token) => {
    try {
      const { data } = await axios.get(`${KADENTRADING_API_URL}/orderHistory`, { headers: { 'Authorization': `Basic ${token}` } });
      this.setState({ history: data });
    } catch (err) {
      console.log(err);
    }
  }
  componentDidMount() {
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      if (c.includes('user=')) {
        const obj = c.replace('user=', '').trim();
        const userObj = JSON.parse(obj);
        if (userObj.token && userObj.dealIDs?.length > 0) {
          this.grabHistory(userObj.token);
        }
      }
    }
  }

  render() {
    const { mobile } = this.props;

    const linkHome = () => {
      openLocalSlug('/');
    }
    return (
      <div style={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
        <div style={{ flex: '0 1 auto' }}>
          <div onClick={linkHome} style={{ cursor: 'pointer' }}>
            <img alt='logo' style={{ width: '200px' }} src={require('../../assets/full_logo.png')} />
          </div>
        </div>
        <div style={{ flex: '1 1 auto', marginTop: mobile ? '3vh' : '13vh', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
          <div onClick={() => {openLocalSlug('/account')}} style={{
            position: 'absolute',
            top: '3vh',
            left: mobile ? '15vw' : '30vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '74px',
            borderRadius: '37px',
            border: '1px solid black',
            paddingRight: '5px',
            cursor: 'pointer',
            ':hover': { backgroundColor: 'rgba(0,0,0,0.2)' }
          }}><ChevronLeft />Back</div>
          <div style={{ fontSize: mobile ? '26pt' : '35pt', fontWeight: 'bold', textAlign: 'center', marginBottom: '3vh', marginTop: mobile ? '10vh' : null }}>
            Order History
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            border: '2px solid black',
            padding: '4vh',
            borderRadius: '10px',
            width: mobile ? '60vw' : '40vw',
            backgroundColor: 'white',
            height: '45vh',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}>
            {
              this.state.history.length > 0 ?
              this.state.history.map((order, orderIdx) => {
                return (
                  <div
                    key={`deal${orderIdx}`}
                    style={{
                      border: '1px solid black',
                      width: mobile ? '70vw' : '35vw',
                      blockSize: 'fit-content',
                      borderRadius: '10px',
                      marginBottom: '15px'
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: 'rgba(0,0,0,0.15)', padding: '10px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ color: 'GrayText', fontSize: '9pt' }}>order placed</span>
                        <span style={{ color: 'GrayText', fontSize: '10pt' }}>{dayjs(order.createdAt).format('MM/DD/YYYY hh:mm A')}</span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ color: 'GrayText', fontSize: '9pt' }}>total</span>
                        <span style={{ color: 'GrayText', fontSize: '10pt' }}>
                          $ {order.products.map(product => product.map).reduce((a, b) => a + b)}
                        </span>
                      </div>
                    </div>
                    <div style={{ marginTop: '10px', padding: '10px' }}>
                      {
                        order.products.map(product => {
                          return (
                            <div style={{ display: 'flex', flexDirection: 'column' }} key={`pro_${product._id}`}>
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  <img
                                    alt={product.productName}
                                    style={{ width: 'auto', height: 'auto', maxHeight: '75px', maxWidth: '100px' }}
                                    src={product.url}
                                  />
                                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '25px' }}>
                                    <span style={mobile ? { fontSize: '11px' } : null}>{product.productName}</span>
                                  </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '25px' }}>
                                  <span style={{ color: 'GrayText', fontSize: mobile ? '9pt' : '10pt' }}>$ {product.map}</span>
                                </div>
                              </div>
                              <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
              :
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                No history at this moment
              </div>
            }
          </div>
        </div>
        <div style={{ flex: '0 1 auto' }}>
          <Footer />
        </div>
        {/* <div style={{
          width: mobile ? '10vw' : '4vw',
          position: 'absolute',
          top: 0,
          left: mobile ? '5vw' : '2vw',
          bottom: 0,
          background: 'linear-gradient(to right, #0D95F2 0%, #0D95F2 33%, #F80871 33%, #F80871 66%, #FFE001 66%, #FFE001 100%)',
          zIndex: -2
        }} /> */}
      </div>
    )
  }
}
