import React from 'react';
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { Celebration, Logout, AccountBox, ChevronLeft, ShoppingBag } from '@mui/icons-material';
import { Footer } from '../universalComponents';
import { useNavigate } from 'react-router-dom';
import quickLinks from '../../utils/quickLinks';
const { openLocalSlug } = quickLinks();

export default function Account(props) {
  const navigate = useNavigate();
  const { mobile } = props;

  const linkHome = () => {
    openLocalSlug('/');
  }
  const logout = () => {
    document.cookie = 'user=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    linkHome();
  }
  const backBtn = () => {
    navigate(-1);
  }

  return (
    <div style={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
      <div style={{ flex: '0 1 auto' }}>
          <div onClick={linkHome} style={{ cursor: 'pointer' }}>
            <img alt='logo' style={{ width: '200px' }} src={require('../../assets/full_logo.png')} />
          </div>
      </div>
      <div style={{ flex: '1 1 auto', marginTop: mobile ? '3vh' : '13vh', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
        <div onClick={backBtn} style={{
          position: 'absolute',
          top: '3vh',
          left:  mobile ? '15vw' : '30vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '74px',
          borderRadius: '37px',
          border: '1px solid black',
          paddingRight: '5px',
          cursor: 'pointer',
          ':hover': { backgroundColor: 'rgba(0,0,0,0.2)' }
        }}><ChevronLeft />Back</div>
        <div style={{ fontSize: '35pt', fontWeight: 'bold', textAlign: 'center', marginBottom: '3vh', marginTop: mobile ? '10vh' : null }}>My Account</div>
        <Box sx={{ width: mobile ? '80vw' : '100%', maxWidth: 800, bgcolor: 'background.paper', border: '2px solid black', borderRadius: '10px' }}>
          <List component='nav' aria-label='main mailbox folders'>
            <ListItemButton onClick={() => {openLocalSlug('/mydeals')}}>
              <ListItemIcon>
                <Celebration />
              </ListItemIcon>
              <ListItemText primary='My Deals' />
            </ListItemButton>
            <ListItemButton onClick={() => {openLocalSlug('/myprofile')}}>
              <ListItemIcon>
                <AccountBox />
              </ListItemIcon>
              <ListItemText primary='Profile' />
            </ListItemButton>
            <ListItemButton onClick={() => {openLocalSlug('/myorderhistory')}}>
              <ListItemIcon>
                <ShoppingBag />
              </ListItemIcon>
              <ListItemText primary='Order History' />
            </ListItemButton>
          </List>
          <Divider />
          <List component='nav' aria-label='secondary mailbox folder'>
            <ListItemButton onClick={logout}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary='Logout' />
            </ListItemButton>
          </List>
        </Box>
      </div>
      <div style={{ flex: '0 1 auto' }}>
        <Footer />
      </div>
      {/* <div style={{
        width: mobile ? '10vw' : '4vw',
        position: 'absolute',
        top: 0,
        left: mobile ? '5vw' : '2vw',
        bottom: 0,
        background: 'linear-gradient(to right, #0D95F2 0%, #0D95F2 33%, #F80871 33%, #F80871 66%, #FFE001 66%, #FFE001 100%)',
        zIndex: -2
      }} /> */}
    </div>
  )
}
