import {
  FormControl, Select, InputLabel, MenuItem, Button, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Grid
} from '@mui/material';
import React, { Component } from 'react';

export default class ProductDetailsDialog extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      quantity: 0,
    };
  }
  render() {
    const { product, mobile, setActiveProduct, addToCart } = this.props;

    return (
      <div>
        <DialogTitle><strong style={{ fontSize: '18pt' }}>{product?.productName}</strong> Product Details</DialogTitle>
        <DialogContent style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ maxHeight: mobile ? '225px' : '600px', width: mobile ? '225px' : '600px', padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {
              product.url ?
              <img
                alt={product.productName}
                style={{ maxWidth: mobile ? '225px' : '600px', maxHeight: mobile ? '225px' : '600px', width: 'auto', height: 'auto' }}
                src={product.url}
              />
              :
              <img alt='ImagePlaceholder' style={{ width: mobile ? '75px' : '175px' }} src={require('../../../assets/imagePlaceholder.png')} />
            }
          </div>
          <fieldset style={{ width: '95%' }}>
            <legend style={{ paddingLeft: '3px', paddingRight: '3px' }}>Basic Details</legend>
            <Grid container spacing={2}>
              <Grid item xs={mobile ? 6 : 2}>
                <strong>$ {product?.map || '--'}</strong>
                <DialogContentText style={{ fontSize: '10pt' }}>MAP</DialogContentText>
              </Grid>
              <Grid item xs={mobile ? 6 : 3}>
                <strong>{product?.brand || '--'}</strong>
                <DialogContentText style={{ fontSize: '10pt' }}>Brand</DialogContentText>
              </Grid>
              <Grid item xs={mobile ? 6 : 3}>
                <strong>{product?.productType || '--'}</strong>
                <DialogContentText style={{ fontSize: '10pt' }}>Product Type</DialogContentText>
              </Grid>
              <Grid item xs={mobile ? 6 : 3}>
                <strong>{product?.category || '--'}</strong>
                <DialogContentText style={{ fontSize: '10pt' }}>Category</DialogContentText>
              </Grid>
              <Grid item xs={mobile ? 6 : 2}>
                <strong>{product?.sku || '--'}</strong>
                <DialogContentText style={{ fontSize: '10pt' }}>SKU</DialogContentText>
              </Grid>
              <Grid item xs={mobile ? 6 : 3}>
                <strong>{product?.pageYield || '--'}</strong>
                <DialogContentText style={{ fontSize: '10pt' }}>Page Yield</DialogContentText>
              </Grid>
              <Grid item xs={mobile ? 6 : 3}>
                <strong>{product?.pcfPageYield || '--'}</strong>
                <DialogContentText style={{ fontSize: '10pt' }}>PCF Page Yield</DialogContentText>
              </Grid>
            </Grid>
          </fieldset>
          <br />
          <fieldset style={{ width: '95%' }}>
            <legend style={{ paddingLeft: '3px', paddingRight: '3px' }}>Printer Info</legend>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <strong>{product?.compatiblePrinters?.join(', ') || '--'}</strong>
                <DialogContentText style={{ fontSize: '10pt' }}>Compatible Printers</DialogContentText>
              </Grid>
              <Grid item xs={6}>
                <strong>{product?.pcfCompatiblePrinters?.join(', ') || '--'}</strong>
                <DialogContentText style={{ fontSize: '10pt' }}>PCF Compatible Printers</DialogContentText>
              </Grid>
            </Grid>
          </fieldset>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="qty-label">Qty</InputLabel>
              <Select
                labelId="qty-label"
                value={this.state.quantity}
                label="Age"
                onChange={(e) => {this.setState({ quantity: e.target.value })}}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {
                  [...Array(20).keys()].map(num => <MenuItem value={num}>{num}</MenuItem>)
                }
              </Select>
            </FormControl>
            <Button
              disabled={!this.state.quantity}
              variant='contained'
              sx={{ bgcolor: '#0D95F2', '&:hover': { 'bgcolor': '#2273F2' }, color: 'white' }}
              onClick={() => addToCart(product, this.state.quantity)}
            >
              Add {this.state.quantity ? this.state.quantity : ''} To Cart
            </Button>
          </div>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Button variant='contained' sx={{ bgcolor: '#0D95F2', '&:hover': { 'bgcolor': '#2273F2' }, color: 'white' }} onClick={setActiveProduct}>Close</Button>
          </div>
        </DialogActions>
      </div>
    )
  }
}
